<template>
    <div>
		<b-modal ref="modalSendImpayes" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("action.envoyer") }}
				</template>
			</template>

			<div class="row">
				<div class="col">
					<div v-if="form_message !== ''" class="col-12 alert alert-warning">{{ form_message }}</div>

					<div class="col-12">
						<div class="form-group">
							<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
							<e-select
								v-model="template"
								id="model_id"
								track-by="model_label"
								label="model_label"
								:placeholder="labelTitleTemplate"
								:deselectLabel="deselectLabel"
								:selectedLabel="selectedLabel"
								:options="templates"
								:searchable="true"
								:allow-empty="false"
								:show-labels="false"
								@input="setModel"
							>
								<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
						</div>
						<div v-if="template">
							<a href="#" @click.prevent="showModel">{{ $t('model.afficher_model') }}</a>
						</div>

						<div class="form-group">
							<label for="commentaire" class="col-form-label">{{ $t("monte.commentaire") }}</label>
							<textarea id="commentaire" class="form-control" rows="3" v-model="commentaire"></textarea>
						</div>

						<div class="mb-3">
							<b-form-checkbox
							id="send_mail_sender"
							v-model="send_mail_sender"
							:value="true"
							:unchecked-value="false"
							>
							{{ $t("monte.envoie_mail_sender") }}
							</b-form-checkbox>
						</div>		

						<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("action.envoyer") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
					</div>
				</div>
				<div v-if="show_model" class="col-12 col-lg-6">
					<div v-if="model_selected" class="border-2 px-3 py-3" v-html="model_selected"></div>
					<LoadingSpinner v-else class="col-12" />
				</div>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Accounting from '@/mixins/Accounting.js'
import Invoice from '@/mixins/Invoice.js'
import Model from "@/mixins/Model.js"
import xss from 'xss'
import Vue from 'vue'

export default {
	name: "ModalSendImpayes",
	mixins: [Accounting, Invoice, Model],
	data () {
		return {
			formats: [],
			send_mail_sender: false,
			templates: [],
			template: null,
			processing: false,
			invoices_ids: null,
			author_id: null,
			form_message: '',
			commentaire: '',
			show_model: false,
			accounting_plan: null,
			model_selected: null,

			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),

			labelTitleFormat: this.getTrad("horse.search_format"),
			labelTitleTemplate: this.getTrad("horse.search_doc")
		}
	},
	methods: {
		async init_component() {
			this.processing = false
			this.template = null
			this.templates = []
			this.show_model = false
			this.model_selected = null

			this.accounting_plan = await this.loadAccountingPlanByEntity(this.author_id)

			this.templates = await this.loadAllModel(this.accounting_plan.accountingplan_id, ['accounting_plan'], 'unpaid_invoice_notification')

			let modal = document.getElementsByClassName('modal-dialog')[0]
			modal.classList.remove('modal-xxl')
		},
		openModal(invoices_ids, author_id) {
			this.invoices_ids = invoices_ids
			this.form_message = ''
			if(this.invoices_ids.split(',').length > 5) {
				this.form_message = this.getTrad("global.warning_lots")
			}
			this.author_id = author_id
			this.$refs.modalSendImpayes.show()
		},
		closeModal() {
			this.$refs.modalSendImpayes.hide()
		},
		async checkForm() {
			
			if(this.template) {
				if (this.processing == false){
					this.processing = true
					await this.sendMailInvoices(this.invoices_ids, "unpaid_invoice_notification", this.template.model_id, this.commentaire, null, this.send_mail_sender)
					.then(() => {
						this.$emit('submit')
						this.closeModal()
						this.processing = false
					})
				}
			}
			else {
				this.failureToast("formulaire.erreur_champs_non_remplis")
			}
		},
		async setModel() {
			this.model_selected = null

			if(this.template) {
				const models = await this.loadModel(this.accounting_plan.accountingplan_id, this.template.model_id, 'accounting_plan')
				const templates = await this.loadTemplate(this.accounting_plan.accountingplan_id, this.template.model_id, 'accounting_plan', Vue.i18n.locale())
				const zone = models[0].zone.find(zone => zone.zonetype.modelzonetype_code == 'unpaid_invoice_notification_body')
				this.model_selected = templates.find(temp => temp.modeltemplate_modelzone == zone.modelzone_id).modeltemplate_content
			}
		},
		showModel() {
			this.show_model = true
			let modal = document.getElementsByClassName('modal-dialog')[0]
			modal.classList.add('modal-xxl')
		},
		cleanHTML(input) {
			return xss(input)
		}
	},
	components: {
		LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
	}
}
</script>